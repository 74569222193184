function init() {
    Array.prototype.forEach.call(document.getElementsByClassName('my-accordion-radios'), function (accordion) {

        let activeKey = null;
        let currentMousedownRadioEl = null;

        function checkAccordionCompletion(accordion, checkForError) {
            Array.prototype.forEach.call(accordion.querySelectorAll('.accordion-item'), function (accordionItem) {
                let hasError = false;
                const sections = accordionItem.querySelectorAll('.sub-question');
                let sectionSelected = 0;
                sections.forEach(function (section) {
                    const subSectionInputChecked = {}; // Per subsectie bijhouden of ik al een checked input heb
                    sectionSelected += section.querySelectorAll('input.form-check-input:checked').length;
                    Array.prototype.forEach.call(section.querySelectorAll('input.form-check-input'), function(input) {
                        if (input.classList.contains('is-invalid')) {
                            hasError = true;
                        }
                        if (input.name in subSectionInputChecked) {
                            return;
                        }
                        const sectionStatusEl = document.getElementById(input.name);
                        sectionStatusEl.classList.remove("yes", "no", "preventive", "curative");
                        sectionStatusEl.innerHTML = '-';
                        if (input.checked) {
                            subSectionInputChecked[input.name] = true;
                            sectionStatusEl.classList.add(input.value);
                            sectionStatusEl.innerHTML = input.value.charAt(0);
                        }
                    });
                });
                // const img = accordionItem.querySelector('img.img-fluid');
                // if (sectionSelected === sections.length) {
                //     img.classList.remove('invisible');
                // } else {
                //     img.classList.add('invisible');
                // }
                if (hasError) {
                    accordionItem.classList.add('my-has-error');
                }
            });
        }

        function updateKeyState(torso, collapseItems, collapseMapper) {
            const bullets = torso.querySelectorAll('.bullet');
            bullets.forEach(function(b) {
                if (b.dataset.key === activeKey) {
                    b.classList.add('active-bullet');
                } else {
                    b.classList.remove('active-bullet');
                }
            });
        }

        // Handle completion check on question clicks
        
        accordion.addEventListener('mousedown', function (e) {
            currentMousedownRadioEl = null;
            if (e.target.classList.contains('form-check-input')) {
                if (e.target.checked) {
                    e.target.checked = false;
                    currentMousedownRadioEl = e.target;
                }
                checkAccordionCompletion(accordion, false);
            }
        });

        accordion.addEventListener('click', function (e) {
            if (e.target.classList.contains('form-check-input')) {
                if (currentMousedownRadioEl && currentMousedownRadioEl === e.target) {
                    e.target.checked = false;
                }
                checkAccordionCompletion(accordion, false);
            }
        });

        // Handle bullets
        const torso = document.getElementById('my-torso');
        if (torso && torso.classList.contains('active')) {
            const collapseItems = accordion.querySelectorAll('.collapse');
            const collapseMapper = {}; // name => collapse
            collapseItems.forEach(function(el) {
                collapseMapper[el.dataset.key] = new window.bootstrap.Collapse(el, {
                    toggle: false
                });
                // Detect when collapse is show (can be if user clicks on the collapse, or if user clicks on the bullet)
                el.addEventListener('show.bs.collapse', function(eShow) {
                    if (activeKey !== el.dataset.key) {
                        activeKey = el.dataset.key;
                        updateKeyState(torso, collapseItems, collapseMapper);
                    }
                });
            });
            torso.addEventListener('click', function (eTorso) {
                if (eTorso.target.classList.contains('bullet')) {
                    const key = eTorso.target.dataset.key;
                    if (activeKey !== key) {
                        activeKey = key;
                    } else {
                        activeKey = null;
                    }
                    collapseMapper[key].toggle();
                    updateKeyState(torso, collapseItems, collapseMapper);
                }
            });
        }

        // Start completion check, because we may already have answers
        checkAccordionCompletion(accordion, true);
    });
}

init();
