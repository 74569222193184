function onSliderChangeEvent(e) {
    if (e.target.classList.contains('form-range')) {
        if (e.target.classList.contains('initial-value-null')) {
            const valueEl = document.querySelector('[data-name-value="' + e.target.id + '"]');
            valueEl.innerHTML = e.target.value;
            valueEl.classList.remove('opacity-25');
        } else {
            onSliderChange(e.target);
        }
    }
}

function onSliderChange(el) {
    const valueEl = document.querySelector('[data-name-value="' + el.id + '"]');
    valueEl.innerHTML = el.value;
    if (!el.classList.contains('initial-value-null')) {
        if (el.value == 0) {
            valueEl.classList.add('opacity-25');
        } else {
            valueEl.classList.remove('opacity-25');
        }
    }
}

function init() {
    document.querySelectorAll('input.form-range').forEach(onSliderChange);
    document.addEventListener('change', onSliderChangeEvent);
}

const requiredNonNullValueRangeInput = document.querySelector('input.initial-value-null');
if (requiredNonNullValueRangeInput) {
    requiredNonNullValueRangeInput.form.addEventListener('submit', function (e) {
        document.querySelectorAll('input.initial-value-null').forEach(function (el) {
            if (el.classList.contains('opacity-25')) {
                e.preventDefault();
            }
        });
    });
    document.querySelectorAll('input.initial-value-null').forEach(function (el) {
        if (el.dataset.hasNullValue) {
            el.classList.add('opacity-25');
            // Note: click doesn't work on mobile.
            // Input doesn't work when clicking on zero the first time, users need to set it to another value first and then go back.
            el.addEventListener('input', function () {
                // PAS OP: hier GEEN change event, want hij staat al op 0 en als je klikt blijft ie op 0 staan.
                const valueEl = document.querySelector('[data-name-value="' + el.id + '"]');
                valueEl.innerHTML = el.value;
                valueEl.classList.remove('opacity-25');
                el.classList.remove('opacity-25');
            });
        }
    });
}

init();