const locationsWrapper = document.querySelector('[data-group-name="locations"]');

const valuesToHide = ['root', 'global'];

function toggle(value) {
    if (valuesToHide.includes(value)) {
        locationsWrapper.classList.add('d-none');
    } else {
        locationsWrapper.classList.remove('d-none');
    }
}

function onChange() {
    toggle(this.value);
}

function init() {
    const roleSelect = document.getElementById('role');
    if (roleSelect) {
        roleSelect.addEventListener('change', onChange);
        toggle(roleSelect.value);
    }
}

init();