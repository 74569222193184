const btn = document.getElementById('my-qr-download-button');
if (btn) {
    btn.addEventListener('click', function () {
        const wrapper = document.getElementById('my-qr-wrapper');
        const filename = wrapper.dataset.name + '.png';
        const svg = wrapper.querySelector('svg');
        var rect = svg.getBoundingClientRect();
        var canvas = document.createElement('canvas');
        canvas.setAttribute('width', rect.width.toString());
        canvas.setAttribute('height', rect.height.toString());
        var ctx = canvas.getContext('2d');
        ctx.fillStyle = svg.style.backgroundColor;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        var img = new Image();
        var data = '<svg xmlns="http://www.w3.org/2000/svg">' + svg.innerHTML + '</svg>';
        var parser = new DOMParser();
        var result = parser.parseFromString(data, 'text/xml');
        var inlineSVG = result.getElementsByTagName("svg")[0];
        inlineSVG.setAttribute('width', rect.width.toString());
        inlineSVG.setAttribute('height', rect.height.toString());
        var svg64 = btoa(new XMLSerializer().serializeToString(inlineSVG));
        var image64 = 'data:image/svg+xml;base64,' + svg64;
        img.onload = function () {
            ctx.drawImage(img, 0, 0, rect.width, rect.height);
            window.URL.revokeObjectURL(image64);
            var png_img = canvas.toDataURL("image/png");
            const createEl = document.createElement('a');
            createEl.href = png_img;
            createEl.download = filename;
            createEl.click();
            createEl.remove();
        }
        img.src = image64;
    });
}