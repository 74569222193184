import './app';
import './tinymce';
import './accordion-radios';
import './range-sliders';
import './form-backend-users';
import './questionnaire';
import './slot';
import './qr';
//import './report';

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));

function toggleInfo(currentIndex, spans) {
    spans.forEach(function (span, index) {
        if (index === currentIndex) {
            span.classList.add('d-inline');
            span.classList.remove('d-none');
        } else {
            span.classList.add('d-none');
            span.classList.remove('d-inline');
        }
    });
}

document.querySelectorAll('.my-info-toggler').forEach(function (el) {
    const spans = el.querySelectorAll('span');
    let currentIndex = 0;
    // Double click toggles the text
    el.addEventListener('dblclick', function () {
        currentIndex = currentIndex === 0 ? 1 : 0;
        toggleInfo(currentIndex, spans);
    });
    // Click selects the text, but only if we are in the toggled mode
    el.addEventListener('click', function () {
        if (currentIndex === 1) {
            let range = document.createRange();
            range.selectNode(spans[1]);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
    });
    toggleInfo(currentIndex, spans);
});

// Toon datalist pas bij 2 karakters - maar werkt niet goed met change in slot.js, dus nu uitgezet.

// function toggleDataList (input, list) {
//     if (input.value.length > 1) {
//         if (!input.getAttribute('list')) {
//             input.setAttribute('list', list.id);
//         }
//     } else {
//         input.removeAttribute('list');
//     }
// }

// document.querySelectorAll('datalist').forEach(function (list) {
//     const input = document.querySelector("input[list='" + list.id + "']");
//     if (!input) {
//         return;
//     }
//     input.addEventListener('input', function() {
//         toggleDataList(input, list);
//     });
//     toggleDataList(input, list);
// });