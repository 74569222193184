const previousQuestionnairesSelect = document.getElementById('my-previous-questionnaires');
if (previousQuestionnairesSelect) {
    previousQuestionnairesSelect.addEventListener('change', function () {
        window.location.href = window.location.href.split('?')[0] + '?previous=' + this.value;
    });
}

function onFileChange(e) {
    
}

Array.prototype.forEach.call(document.querySelectorAll('input[type="file"]'), function(input) {
    const preview = document.getElementById('my-preview-' + input.id);
    const img = preview.querySelector('img');
    const hiddenInputForDelete = document.getElementById('hidden_' + input.id);
    const deleteIcon = document.createElement('button');
    deleteIcon.className = 'btn btn-sm btn-danger position-absolute';
    deleteIcon.innerHTML = 'X';
    if (!img.dataset.originalSrc) {
        preview.classList.add('d-none');
    }

    deleteIcon.addEventListener('click', function(eDelete) {
        eDelete.preventDefault();
        img.src = null;
        img.classList.add('d-none');
        hiddenInputForDelete.value = '1';
        input.value = '';
        preview.classList.add('d-none');
    });
    input.addEventListener('change', function() {
        if (input.files.length) {
            const file = input.files[0];
            const url = URL.createObjectURL(file);
            img.src = url;
            img.classList.remove('d-none');
            hiddenInputForDelete.value = '';
            preview.classList.remove('d-none');
        }
    });
    preview.appendChild(deleteIcon);
});