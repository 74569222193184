Array.prototype.forEach.call(document.querySelectorAll('datalist'), function(datalist) {
    const inputId = datalist.dataset.inputId;
    const input = document.getElementById(inputId);
    if(!input) {
        return;
    }
    input.addEventListener('change', function() {
        const selectedItem = Array.from(this.list.options).find(item => item.value == this.value);
        if (selectedItem && selectedItem.dataset.model) {
            // Populate the other form input fields if requested
            const model = JSON.parse(selectedItem.dataset.model);
            Array.prototype.forEach.call(input.form.querySelectorAll('[data-list-src]'), function(otherInput) {
                if (otherInput.nodeName.toLowerCase() === 'select') {
                    Array.prototype.forEach.call(otherInput.options, function(option) {
                        option.selected = option.value === model[otherInput.id];
                    });
                } else {
                    otherInput.value = model[otherInput.id];
                }
            });
        }
    });
});