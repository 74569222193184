import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default/icons.min.js';

/* Required TinyMCE components */
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/models/dom/model.min.js';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.js';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/media';

// Below is from the documentation, but gives an error...
// https://www.tiny.cloud/docs/tinymce/latest/vite-es6-npm/
// /* content UI CSS is required */
// import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.js';
// /* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
// import contentCss from 'tinymce/skins/content/default/content.js';

// But this works
/* content UI CSS is required */
import 'tinymce/skins/ui/oxide/content.js';
/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import 'tinymce/skins/content/default/content.js';

let activeEditor, activeDialog = null;

tinymce.init({
    license_key: 'gpl',
    selector: 'textarea.my-html-editor',
    menubar: false,
    plugins: 'code lists fullscreen image link table wordcount autoresize media',
    //toolbar: 'blocks | bold italic | removeformat code fullscreen | bullist numlist | myMediaButton | myInternalLinkButton link unlink | table | wordcount',
    toolbar: 'blocks | bold italic | removeformat code fullscreen | bullist numlist | link unlink | wordcount',
    table_style_by_css: true,
    table_default_attributes: {
        class: 'table table-striped'
    },
    content_style: 'img.img-fluid {max-width:100%;} table.table {width:100%;}',
    table_default_styles: {},
    media_poster: false,
    media_live_embeds: true,
    media_dimensions: false,
    table_use_colgroups: false,
    table_header_type: 'sectionCells',
    table_sizing_mode: 'responsive',
    table_resize_bars: false,
    statusbar: false,
    toolbar_sticky: true,
    relative_urls: true,
    document_base_url: document.documentElement.dataset.uri,
    resize: true,
    skin_url: 'default',
    content_css: 'default',
    setup: function (editor) {
        editor.ui.registry.addButton('myMediaButton', {
            icon: 'image',
            tooltip: 'Insert media',
            onAction: function () {
                activeEditor = editor;
                activeDialog = editor.windowManager.openUrl({
                    title: 'Test',
                    url: document.documentElement.dataset.uri + '/backend/media?editor=1'
                });
            }
        });
    }
});

window.addEventListener('message', (event) => {
    const data = event.data;
    if (data.mceAction === 'myAddMedia') {
        const {id, value} = data.data;
        if (activeEditor) {
            activeDialog.close();
            activeEditor.insertContent(`<p><img class="img-fluid" data-media-id="${id}" src="${value}"></p>`);
            activeDialog = null;
            activeEditor = null;
        }
    }
});